import React from "react";
import Homeshopbycategory from "../HomeShopbycategory/Homeshopbycategory";
import Homeonsellingproduct from "../Homeonsellingproduct/Homeonsellingproduct";
import Homeoffer1 from "../Homeoffer1/Homeoffer1";
import Bannerimage from "../Bannerimage2/Bannerimage";
import Bannerimage_3 from "../Bannerimage_3/Bannerimage_3";
import Bannerimage_split from "../Bannerimage_split/Bannerimage_split";
import Get_experttip from "../Get_experttip/Get_experttip";
import SlideShow from "../Banner/SlideShow";
import Otherproducts from "../Productbycategory/Otherproducts";
import Listbrands from "../Brands/Listbrands";
import { Box } from "@mui/material";
import { PopularSerach } from "../../Icons/Icons";
import { Home_bg_Body } from "../../client-api/ApiHomeBanner";
import { imagemain } from "../../client-api/ApiHome";
import { Getfetchsettings } from "../../client-api/Apicod";
import Newhome from "../Newdesign/Newhome";
import { Circularrotate } from "../../Error";

const Home = () => {
  React.useEffect(() => {
    window.scrollTo({ behavior: "smooth", top: 0 });
  }, []);
   const { data: homepagetheme, isLoading } = Getfetchsettings();
  const { data } = Home_bg_Body();
  return (
    <>
      <Box
        sx={{
          backgroundImage: `url(${imagemain}/${data?.[0]?.image})`,
          backgroundRepeat: "repeat",
        }}
      >
        { isLoading?<Circularrotate />:
        homepagetheme?.template === "Normal" ? (
          <Newhome />
        ) : (
          <>
        <Homeoffer1 />
        <Homeshopbycategory />
        <Otherproducts />
        <SlideShow />
        <Otherproducts product="featured" />
        <Bannerimage />
        <Listbrands />
        <Bannerimage_3 />
        <Homeonsellingproduct />
        <Bannerimage_split />
        <Get_experttip />
        <PopularSerach />
        </>
        )}
      </Box>
    </>
  );
};

export default Home;
